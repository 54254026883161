a {
  color: #030303;
  text-decoration: none;
}

a:hover {
  color: #535353;
  text-decoration: none;
}

.table thead th {
  font-size: 21px;
  border-top: none;
}

textarea,
select,
input,
button {
  outline: none;
}

.form-control:focus {
  border-color: inherit;
  box-shadow: none;
}

:focus {
  outline: none;
}

.is-dragging {
  z-index: 2000;
}

.table tr th {
  font-weight: 300;
  white-space: nowrap;
}

.table td,
.table th {
  padding-left: 5;
  padding-right: 5;
}

.vp-controls-wrapper {
  display: none !important;
}
